<template>
    <r-e-dialog title="添加竞拍" :visible.sync="dialogVisible" show-footer top="10vh" width="700px"
                @click-submit="clickSubmit" @click-cancel="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="竞拍项目" prop="biddingProjectName">
                    <el-input v-model="formPublish.biddingProjectName" placeholder="请输入竞拍项目"/>
                </el-form-item>
                <el-form-item label="竞买号" prop="biddingNumber">
                    <el-input v-model="formPublish.biddingNumber" placeholder="请输入竞买号"/>
                </el-form-item>
            </div>
            <!--<div class="flex">
                <el-form-item label="公司名称" prop="companyName">
                    <el-input v-model="formPublish.companyName" placeholder="请输入公司名称"/>
                </el-form-item>
                <el-form-item label="竞拍人" prop="leasorName">
                    <el-input v-model="formPublish.leasorName" placeholder="请输入竞拍人"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="手机号" prop="leasorPhone">
                    <el-input v-model="formPublish.leasorPhone" placeholder="请输入手机号"/>
                </el-form-item>
                <el-form-item label="身份证" prop="leasorIdcard">
                    <el-input v-model="formPublish.leasorIdcard" placeholder="请输入身份证"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="竞拍状态" prop="bidStatus">
                    <div style="width: 200px;">
                        <el-radio-group v-model="formPublish.bidStatus">
                            <el-radio-button :label="2">成交</el-radio-button>
                            <el-radio-button :label="1">出局</el-radio-button>
                        </el-radio-group>
                    </div>
                </el-form-item>
                <el-form-item label="竞买号" prop="biddingNumber">
                    <el-input v-model="formPublish.biddingNumber" placeholder="请输入竞买号"/>
                </el-form-item>
            </div>-->
            <div class="flex">
                <el-form-item label="价格" prop="bidPrice">
                    <el-input-number v-model="formPublish.bidPrice" :precision="2" placeholder="请输入价格"
                                     style="width: 200px"/>
                </el-form-item>
                <el-form-item label="竞拍时间" prop="bidDate">
                    <el-date-picker v-model="formPublish.bidDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss" placeholder="请选择竞拍时间" style="width: 200px"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {getLeasorDetail,biddingAdd} from '@/api/shops-lease';
    import {MessageInfo, MessageError, MessageSuccess} from "@custom/message";
    export default {
        name: "layer-add-auction",
        props: {
            shopApartmentUuid: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    biddingProjectName: null,
                    companyName: null,
                    leasorUuid: null,
                    leasorName: null,
                    leasorPhone: null,
                    leasorIdcard: null,
                    bidStatus: null,
                    biddingNumber: null,
                    bidPrice: null,
                    bidDate: null,
                },
                rules: {
                    biddingProjectName: [{required: true, message: '请输入竞拍项目', trigger: 'blur'}],
                    companyName: [{required: true, message: '请输入公司名称', trigger: 'blur'}],
                    leasorName: [{required: true, message: '请输入竞拍人', trigger: 'blur'}],
                    leasorPhone: [{required: true, message: '请输入手机号', trigger: 'blur'}],
                    leasorIdcard: [{required: true, message: '请输入身份证', trigger: 'blur'}],
                    biddingNumber: [{required: true, message: '请输入竞买号', trigger: 'blur'}],
                    bidPrice: [{required: true, message: '请输入价格', trigger: 'blur'}],
                    bidStatus: [{required: true, message: '请选择竞拍状态', trigger: 'change'}],
                    bidDate: [{required: true, message: '请选择竞拍时间', trigger: 'change'}],
                },
            };
        },
        components: {},
        async mounted() {
        },
        methods: {
            openDialog() {
                this.dialogVisible = true;
            },

            clickSubmit() {
                let that = this;
                // 表单提交
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        let {
                            biddingProjectName, companyName, leasorUuid, leasorName, leasorPhone, leasorIdcard,
                            bidStatus, biddingNumber, bidPrice, bidDate
                        } = that.formPublish;
                        bidPrice = parseInt((bidPrice * 100).toFixed(0));
                        let shopApartmentUuid = that.shopApartmentUuid;
                        const loading = this.$loading({lock: true, text: "请您稍后正在打开...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                        bidDate = new Date(bidDate);
                        biddingAdd({
                            shopApartmentUuid, biddingProjectName, companyName, leasorUuid, leasorName,
                            leasorPhone, leasorIdcard, bidStatus, biddingNumber, bidPrice, bidDate
                        }).then(res => {
                            MessageSuccess('添加竞拍成功');
                            that.clickCancel();
                            loading.close();
                        }).catch(err => {
                            MessageError('添加竞拍失败');
                            loading.close();
                        });
                    }
                });
            },
            clickCancel() {
                this.$refs["formPublish"].resetFields();
                this.dialogVisible = false;
            },
            getLeasorDetail(value){
                getLeasorDetail({idcard: value}).then(res => {
                    let {info: {name, phone, uuid}} = res;
                    if (uuid) this.formPublish.leasorUuid = uuid;
                    if (phone) this.formPublish.leasorPhone = phone;
                    if (name) this.formPublish.leasorName = name;
                });
            }
        },
        watch: {
            'formPublish.leasorIdcard'(value){
                if (value.length === 16 || value.length === 18) {
                    this.getLeasorDetail(value);
                }
            }
        }
    }
</script>

<style scoped>

</style>